import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import Ligas from "./pages/Ligas";
import MyNavbar from "./components/MyNavbar";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import DynamicLeaguePage from "./pages/DynamicLeaguePage";
import field1 from "./images/field1.png";
import axios from "axios";

function App() {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch leagues from your websitebackend API
  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const response = await axios.get(
          "https://canutillofut7.com/api/leagues"
        );
        setLeagues(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching leagues:", error);
        setError("Failed to load leagues");
        setLoading(false);
      }
    };

    fetchLeagues();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Display a loading message while leagues are being fetched
  }

  if (error) {
    return <div>{error}</div>; // Display error message if there's a problem fetching data
  }
  console.log(leagues);

  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100%",
        minHeight: "100vh",
        fontFamily: "Karantina",
        paddingBottom: "300px",
      }}
    >
      <Router>
        <MyNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ligas" element={<Ligas />} />

          {/* Dynamically generated routes based on leagues */}
          {leagues.map((league) => (
            <Route
              key={league.league_id}
              path={`/ligas/${league.league_id}`} // Change this to use league_id
              element={
                <DynamicLeaguePage
                  bannerImage={field1}
                  bannerText={league.league_name}
                  leagueName={league.league_id} // Pass league_id here
                  themeColor={league.theme_color}
                  trackPlayers={league.track_players}
                  trackGoals={league.track_goals}
                />
              }
            />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
