import TeamStanding from "./TeamStanding";
import "./MyStandingsTable.css";
import { blendWithWhite } from "../Functions/Colors";

const MyStandingsTable = ({
  standings,
  leagueName,
  displayHeading = false,
  themeColor,
}) => {
  const tableShadowColor = blendWithWhite(themeColor, 0.5);
  return (
    <div
      className="table-container"
      style={{
        boxShadow: `0 0 15px 0.5px ${tableShadowColor}`,
      }}
    >
      {displayHeading ? (
        <div className="table-title">{leagueName}</div>
      ) : (
        <div className="table-title">Tabla</div>
      )}

      {/* Render table headers */}
      <div className="row-container">
        <TeamStanding
          position="POS"
          teamName="EQUIPO"
          wins="PG"
          draws="PE"
          losses="PP"
          played="PJ"
          goalsFor="GF"
          goalsAgainst="GC"
          goalDifference="DG"
          points="PTS"
          league={leagueName}
          themeColor={themeColor}
        />
        {/* Map over the sorted standings data */}
        {standings.map((team, index) => {
          console.log("Team data:", team); // Log the entire team object
          return (
            <TeamStanding
              key={`${team.team_id}-${leagueName}`} // Use team_id as the unique key
              position={index + 1}
              teamName={team.team_name}
              wins={team.wins}
              draws={team.draws}
              losses={team.losses}
              played={team.games_played}
              goalsFor={team.goals_for}
              goalsAgainst={team.goals_against}
              goalDifference={team.goal_difference}
              points={team.points}
              themeColor={themeColor}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MyStandingsTable;
