import React from "react";
import MyPageBanner from "../components/MyPageBanner";
import field1 from "../images/field1.png";
import coed_miercoles from "../images/COED_MIERCOLES.JPG";
import "./Home.css";

function Home() {
  return (
    <div className="page-layout">
      <MyPageBanner imageUrl={field1} text="Canutillo FUT" />
      <img
        className="advertising-image"
        alt="Coed Miercoles"
        src={coed_miercoles}
      ></img>
    </div>
  );
}

export default Home;
