import React, { useEffect, useState } from "react";
import "./MatchCard.css";
import { adjustBrightness, blendWithWhite } from "../Functions/Colors";

function MatchCard({
  displayLeague = false,
  team1,
  team1Score,
  team2,
  team2Score,
  league,
  time,
  date,
  status,
  themeColor: parentThemeColor, // New prop from parent
}) {
  const [themeColor, setThemeColor] = useState(parentThemeColor || "#ffffff");
  const [leagueName, setLeagueName] = useState("");

  useEffect(() => {
    const fetchThemeColor = async () => {
      console.log("Fetching theme color for league:", league);

      try {
        const response = await fetch(
          `https://canutillofut7.com/api/leagues/${league}/themeColor`
        );
        const data = await response.json();
        console.log("Fetched theme color data:", data);
        if (data.themeColor) {
          setThemeColor(data.themeColor);
        }
      } catch (error) {
        console.error("Error fetching theme color:", error);
      }
    };

    // Only fetch theme color if not provided by parent
    if (!parentThemeColor && league) {
      fetchThemeColor();
    }
  }, [league, parentThemeColor]);

  useEffect(() => {
    const fetchLeagueName = async () => {
      if (displayLeague && league) {
        try {
          const response = await fetch(
            `https://canutillofut7.com/api/leagues/${league}/name`
          );
          const data = await response.json();
          setLeagueName(data.league_name);
        } catch (error) {
          console.error("Error fetching league name:", error);
        }
      }
    };

    fetchLeagueName();
  }, [displayLeague, league]);

  const shadowColor = blendWithWhite(themeColor, 0.5);
  const secondaryColor = adjustBrightness(themeColor, 0.4);
  const tertiaryColor = adjustBrightness(themeColor, 0.2);

  return (
    <div
      className="match-card"
      style={{
        "--tertiary-color": tertiaryColor,
        "--shadow-color": shadowColor,
      }}
    >
      <div className="teamsScore-Container">
        <TeamScoreContainer
          team={team1}
          score={team1Score}
          status={status}
          themeColor={themeColor}
          secondaryColor={secondaryColor}
          home={true}
        />
        <TeamScoreContainer team={team2} score={team2Score} status={status} />
      </div>
      <DetailsMatchCard
        time={time}
        date={date}
        displayLeague={displayLeague}
        leagueName={leagueName}
      />
    </div>
  );
}

function TeamScoreContainer({
  team,
  score,
  status,
  themeColor = "#111111",
  secondaryColor = "#111111",
  home = false,
}) {
  return (
    <div
      className="teamScoreContainer"
      style={{
        "--theme-color": themeColor,
        "--secondary-color": secondaryColor,
        borderBottomLeftRadius: home ? "0px" : "8px",
        borderBottomRightRadius: home ? "0px" : "8px",
        borderTopLeftRadius: home ? "8px" : "0px",
        borderTopRightRadius: home ? "8px" : "0px",
      }}
    >
      <div className="team-name-text">{team}</div>
      <ScoreContainer score={score} status={status} />
    </div>
  );
}

function ScoreContainer({ score, status }) {
  return (
    <div className={`scoreContainer ${status}`}>
      {score !== undefined && score}
    </div>
  );
}

function DetailsMatchCard({ time, date, displayLeague, leagueName }) {
  return (
    <div className="details">
      {displayLeague && <span className="league">{leagueName || "TBA"}</span>}
      <span className="time">{time ? time : "TBA"}</span>
      <span className="date">{date ? date : "Date Not Available"}</span>
    </div>
  );
}

export default MatchCard;
