import React, { useState, useEffect } from "react";
import MatchCard from "./MatchCard";
import "./Partidos.css";
import axios from "axios";
import { format, startOfWeek, addDays, parse, isValid } from "date-fns";
import { formatGameDate, formatGameTime } from "../Functions/Formatting";

const DATE_FORMAT = "ddMMMyyyy";

const Partidos = ({ leagueFilter, themeColor }) => {
  const [gamesByDate, setGamesByDate] = useState({});
  const [currentWeek, setcurrentWeek] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dateLoading, setDateLoading] = useState(false);

  // Set initial Monday or current date based on filter
  useEffect(() => {
    const today = new Date();
    const initialDate = startOfWeek(today, { weekStartsOn: 1 });
    setcurrentWeek(format(initialDate, DATE_FORMAT).toUpperCase());
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      setDateLoading(true); // Set date-specific loading
      try {
        let endpoint;
        const weekMonday = format(
          startOfWeek(parse(currentWeek, DATE_FORMAT, new Date()), {
            weekStartsOn: 1,
          }),
          "yyyy-MM-dd" // MySQL-compatible format
        );
        endpoint = `https://canutillofut7.com/api/leagues/${leagueFilter}/games/week/${weekMonday}`;

        const response = await axios.get(endpoint);

        console.log("Fetched games data:", response.data);

        setGamesByDate((prev) => ({ ...prev, [currentWeek]: response.data }));
        setLoading(false); // Initial loading finished
      } catch (error) {
        console.error("Error loading games data:", error);
        setError("Failed to load games data.");
      } finally {
        setDateLoading(false); // Reset date-specific loading
      }
    };

    if (currentWeek) {
      fetchGames();
    }
  }, [currentWeek, leagueFilter]);

  const changePeriod = (days) => {
    const newDate = addDays(parse(currentWeek, DATE_FORMAT, new Date()), days);
    if (isValid(newDate))
      setcurrentWeek(format(newDate, DATE_FORMAT).toUpperCase());
  };

  if (loading) return <div>Loading games...</div>;
  if (error) return <div>{error}</div>;

  const gamesForcurrentWeek = gamesByDate[currentWeek] || [];

  return (
    <div className="matchcard-container">
      <div className="period-navigation d-flex justify-content-between align-items-center">
        <button
          className="btn btn-secondary text-center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => changePeriod(-7)}
        >
          Anterior
        </button>
        <span className="mx-3">Semana: {formatGameDate(currentWeek)}</span>
        <button
          className="btn btn-primary text-center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => changePeriod(7)}
        >
          Proximo
        </button>
      </div>

      {dateLoading && gamesForcurrentWeek.length > 0 ? (
        <div>Loading new date...</div>
      ) : gamesForcurrentWeek.length > 0 ? (
        gamesForcurrentWeek.map((game) => (
          <MatchCard
            key={game.game_id}
            team1={game.team1_name}
            team2={game.team2_name}
            time={formatGameTime(game.game_time)}
            date={formatGameDate(game.game_date)}
            status={
              game.team1_score !== null && game.team2_score !== null
                ? "played"
                : "unplayed"
            }
            team1Score={game.team1_score}
            team2Score={game.team2_score}
            league={game.league_id}
            displayLeague={false}
            themeColor={themeColor}
          />
        ))
      ) : (
        <p>No hay partidos.</p>
      )}
    </div>
  );
};

export default Partidos;
