import React, { useState, useEffect } from "react";
import MatchCard from "./MatchCard";
import "./Partidos.css";
import axios from "axios";
import { formatGameDate, formatGameTime } from "../Functions/Formatting";

const PartidosFinal = ({ leagueFilter, themeColor }) => {
  const [gamesData, setGamesData] = useState({});
  const [rounds, setRounds] = useState([]);
  const [currentRoundIndex, setCurrentRoundIndex] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [periodLoading, setPeriodLoading] = useState(false);

  // The currently selected round
  const currentRound = rounds[currentRoundIndex];

  // Fetch valid rounds for finals
  useEffect(() => {
    const fetchValidRounds = async () => {
      try {
        const response = await axios.get(
          `https://canutillofut7.com/api/leagues/${leagueFilter}/games/finals`
        );

        console.log("Valid rounds (ordered):", response.data);

        setRounds(response.data);
        if (response.data.length > 0) {
          // Default to the latest round
          setCurrentRoundIndex(response.data.length - 1);
        }
      } catch (error) {
        console.error("Error fetching valid rounds:", error);
        setError("Failed to load valid rounds.");
      } finally {
        setLoading(false);
      }
    };

    fetchValidRounds();
  }, [leagueFilter]);

  // Fetch games for the current finals round
  useEffect(() => {
    const fetchGames = async () => {
      setPeriodLoading(true);
      try {
        if (currentRound) {
          const endpoint = `https://canutillofut7.com/api/leagues/${leagueFilter}/games/finals/round/${currentRound}`;
          console.log("Fetching games from:", endpoint);

          const response = await axios.get(endpoint);

          console.log("Fetched games data:", response.data);

          setGamesData((prev) => ({
            ...prev,
            [currentRound]: response.data,
          }));
        }
      } catch (error) {
        console.error("Error loading games data:", error);
        setError("Failed to load games data.");
      } finally {
        setPeriodLoading(false);
      }
    };

    if (currentRound) {
      fetchGames();
    }
  }, [currentRound, leagueFilter]);

  // Change the selected round
  const changeRound = (direction) => {
    setCurrentRoundIndex((prevIndex) =>
      Math.min(Math.max(prevIndex + direction, 0), rounds.length - 1)
    );
  };

  // Loading and error handling
  if (loading) return <div>Loading rounds...</div>;
  if (error) return <div>{error}</div>;

  const gamesForCurrentRound = gamesData[currentRound] || [];

  return (
    <div className="matchcard-container">
      {/* Navigation for finals rounds */}
      <div className="period-navigation d-flex justify-content-between align-items-center">
        <button
          className="btn btn-secondary text-center"
          onClick={() => changeRound(-1)}
          disabled={currentRoundIndex === 0}
        >
          Anterior
        </button>
        <span className="mx-3">{currentRound || "No rounds available"}</span>
        <button
          className="btn btn-primary text-center"
          onClick={() => changeRound(1)}
          disabled={currentRoundIndex === rounds.length - 1}
        >
          Próximo
        </button>
      </div>

      {/* Display games for the current round */}
      {periodLoading && gamesForCurrentRound.length > 0 ? (
        <div>Loading games...</div>
      ) : gamesForCurrentRound.length > 0 ? (
        gamesForCurrentRound.map((game) => (
          <MatchCard
            key={game.game_id}
            team1={game.team1_name}
            team2={game.team2_name}
            time={formatGameTime(game.game_time)}
            date={formatGameDate(game.game_date)}
            status={
              game.team1_score !== null && game.team2_score !== null
                ? "played"
                : "unplayed"
            }
            team1Score={game.team1_score}
            team2Score={game.team2_score}
            league={game.league_id}
            displayLeague={false}
            themeColor={themeColor}
          />
        ))
      ) : (
        <p>No hay partidos en esta ronda.</p>
      )}
    </div>
  );
};

export default PartidosFinal;
