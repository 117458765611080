import React, { useState, useEffect } from "react";
import MatchCard from "./MatchCard";
import "./Partidos.css";
import axios from "axios";
import { format, startOfWeek, addDays, parse, isValid } from "date-fns";
import { formatGameDate, formatGameTime } from "../Functions/Formatting";

const DATE_FORMAT = "ddMMMyyyy";

const Partidos = ({ leagueFilter, themeColor, isFinal = false }) => {
  const [gamesByDate, setGamesByDate] = useState({});
  const [currentWeek, setcurrentWeek] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dateLoading, setDateLoading] = useState(false);

  // Set initial Monday or current date based on filter
  useEffect(() => {
    const today = new Date();
    const initialDate = startOfWeek(today, { weekStartsOn: 1 });
    setcurrentWeek(format(initialDate, DATE_FORMAT).toUpperCase());
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      setDateLoading(true);
      try {
        let endpoint;

        if (isFinal) {
          // Fetch games by playoff round
          endpoint = `https://canutillofut7.com/api/leagues/${leagueFilter}/games/finals`;
        } else {
          // Fetch games by week
          const weekMonday = format(
            startOfWeek(parse(currentWeek, DATE_FORMAT, new Date()), {
              weekStartsOn: 1,
            }),
            "yyyy-MM-dd"
          );
          endpoint = `https://canutillofut7.com/api/leagues/${leagueFilter}/games/week/${weekMonday}`;
        }

        const response = await axios.get(endpoint);

        console.log("Fetched games data:", response.data);

        if (isFinal) {
          setGamesByDate(response.data); // Directly store finals data
        } else {
          setGamesByDate((prev) => ({ ...prev, [currentWeek]: response.data }));
        }
        setLoading(false);
      } catch (error) {
        console.error("Error loading games data:", error);
        setError("Failed to load games data.");
      } finally {
        setDateLoading(false);
      }
    };

    if (currentWeek || isFinal) {
      fetchGames();
    }
  }, [currentWeek, leagueFilter, isFinal]);

  const changePeriod = (days) => {
    const newDate = addDays(parse(currentWeek, DATE_FORMAT, new Date()), days);
    if (isValid(newDate))
      setcurrentWeek(format(newDate, DATE_FORMAT).toUpperCase());
  };

  if (loading) return <div>Loading games...</div>;
  if (error) return <div>{error}</div>;

  const gamesForCurrentWeek = isFinal
    ? gamesByDate
    : gamesByDate[currentWeek] || [];

  return (
    <div className="matchcard-container">
      {!isFinal && (
        <div className="period-navigation d-flex justify-content-between align-items-center">
          <button
            className="btn btn-secondary text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => changePeriod(-7)}
          >
            Anterior
          </button>
          <span className="mx-3">Semana: {formatGameDate(currentWeek)}</span>
          <button
            className="btn btn-primary text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => changePeriod(7)}
          >
            Próximo
          </button>
        </div>
      )}

      {dateLoading && gamesForCurrentWeek.length > 0 ? (
        <div>Loading new date...</div>
      ) : gamesForCurrentWeek.length > 0 ? (
        gamesForCurrentWeek.map((game) => (
          <MatchCard
            key={game.game_id}
            team1={game.team1_name}
            team2={game.team2_name}
            time={formatGameTime(game.game_time)}
            date={formatGameDate(game.game_date)}
            status={
              game.team1_score !== null && game.team2_score !== null
                ? "played"
                : "unplayed"
            }
            team1Score={game.team1_score}
            team2Score={game.team2_score}
            league={game.league_id}
            displayLeague={false}
            themeColor={themeColor}
          />
        ))
      ) : (
        <p>No hay partidos.</p>
      )}
    </div>
  );
};

export default Partidos;
