export const formatGameDate = (gameDate) => {
  // Parse the date string to a Date object
  const date = new Date(gameDate);

  // Define an array for month abbreviations
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
  const month = months[date.getMonth()]; // Get month abbreviationdigits of the year

  // Combine into the desired format
  return `${day} ${month}`;
};

export const formatGameTime = (gameTime) => {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes] = gameTime.split(":").map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? "pm" : "am";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12; // 0 should convert to 12

  // Ensure minutes are always two digits
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Return the formatted time
  return `${formattedHours}:${formattedMinutes}${period}`;
};
