// src/Functions/Colors.js

/**
 * Convert hex to RGB.
 * @param {string} hex - Hexadecimal color code.
 * @returns {Array} - RGB array [r, g, b].
 */
export const hexToRgb = (hex) => {
  return hex
    .replace("#", "")
    .match(/.{1,2}/g)
    .map((x) => parseInt(x, 16));
};

/**
 * Convert RGB to hex.
 * @param {number} r - Red value.
 * @param {number} g - Green value.
 * @param {number} b - Blue value.
 * @returns {string} - Hexadecimal color code.
 */
export const rgbToHex = (r, g, b) => {
  return `#${[r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("")}`;
};

/**
 * Blend a color with white.
 * @param {string} hexColor - Hexadecimal color code.
 * @param {number} blendFactor - Value between 0 and 1 for blending.
 * @returns {string} - Blended color in hex format.
 */
export const blendWithWhite = (hexColor, blendFactor) => {
  const rgb = hexToRgb(hexColor);
  const white = [255, 255, 255];
  const blended = rgb.map((channel, i) =>
    Math.round(channel * (1 - blendFactor) + white[i] * blendFactor)
  );
  return rgbToHex(...blended);
};

/**
 * Adjust the brightness of a hex color.
 * @param {string} hex - The hex color code (e.g., "#ffffff").
 * @param {number} factor - The factor to adjust brightness (e.g., 0.5 for darker, >1 for brighter).
 * @returns {string} - The adjusted hex color.
 */
export const adjustBrightness = (hex, factor) => {
  const rgb = parseInt(hex.slice(1), 16);
  let r = Math.min(255, Math.max(0, ((rgb >> 16) & 0xff) * factor));
  let g = Math.min(255, Math.max(0, ((rgb >> 8) & 0xff) * factor));
  let b = Math.min(255, Math.max(0, (rgb & 0xff) * factor));

  r = Math.round(r).toString(16).padStart(2, "0");
  g = Math.round(g).toString(16).padStart(2, "0");
  b = Math.round(b).toString(16).padStart(2, "0");

  return `#${r}${g}${b}`;
};
